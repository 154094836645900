import React, { Component } from 'react'
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import "../../styles/backend.scss"
 
import toaster from "toasted-notes";
import 'toasted-notes/src/styles.css';
const allowedTypes = /\bdoc\b|\bdocx\b|\bpdf\b|\bzip\b|\brar\b/;

export default class CareerFormClass extends Component {
    // constructor(props) {
    //     // this.formRef = React.createRef()
    // }
    state = {
        contactType: '',
        name: '',
        phone: '',
        email: '',
        message: '',
        selectedFile: '',
        outPutText: ''


    }
    handleChange = (e) => {

        switch (e.target.name) {
            case 'selectedFile':
                this.setState({ selectedFile: e.target.files[0] });
                this.setState({ outPutText: e.target.files[0].name});
                break;
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    }
    resetInput = () => {
        this.outPutText= ''
    }
    handleSubmit = (formValues, restForm) => {
        const { contactType, name, phone, email, message } = formValues;
        const { selectedFile } = this.state;

        let formData = new FormData();
        formData.append('contactType',`Official website: ${this.props.contactTitle}` || "Official website: Career form");
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('message', message);
        formData.append('selectedFile', selectedFile);
        
        axios.post("https://backend.s7codedesign.com", formData)

        .then(function (response) {
            if (response.status === 200) {
                toaster.notify("You application has been successfully sent. We will answer you as soon as possible.", {
                    duration: 3000,
                    position: "top-right"
                  });

               restForm()
 
            }

        })
        .catch(function (error) {
            toaster.notify("Application sending fail. Our develop team is working to fix a problem.", {
                duration: 3000,
                position: "top-right"
              });
        })

    }
    render() {
        return <div></div>
        return (
            <Formik
            initialValues={{contactType: 'Official website: Career form', name: '', email: '', phone: '', message:'', selectedFile: '' }}
            validate={values => {
                let errors = {};

                // email
                if (!values.email) {
                    errors.email = 'Email field is required';
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                    errors.email = 'Invalid email address';
                }
                // name
                if (!values.name) {
                    errors.name = 'Name field is required';
                } else if (
                    !/^[A-ZšđčćžŠĐČŽĆ ]{2,}$/i.test(values.name)
                ) {
                    errors.name = 'Name must contain at least 2 letters.';
                }
                // phone
                if (values.phone && !/^[0-9._%+-]{6,}$/i.test(values.phone)) {
                    errors.phone = 'Invalid phone number format.';
                }
                // message
                if (!values.message) {
                    errors.message = 'Message field is required';
                } else if (
                    !/^[A-ZšđčćžŠĐČŽĆ 0-9.,;:" '_%+-]{10,}$/i.test(values.message)
                ) {
                    errors.message = 'Please fill up message field';
                }
                // files
                const {selectedFile} = this.state;

                if (!selectedFile) {
                    errors.selectedFile = 'File attachment is mandatory';
                } else if (
                    selectedFile && !selectedFile.name.split('.')[1].match(allowedTypes)
                ) {
                    errors.selectedFile = 'Wrong file format.';
                }
                return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setTimeout(() => {
                    setSubmitting(false);
                    this.handleSubmit(values, resetForm);
                    this.setState({outPutText: ''})
                }, 400);
            }}
        >
            {({ isSubmitting }) => (
                <Form>
                    <Field type="hidden" name="contactType" className="invisible-field"/>
                    <div className="form-row mb-3">
                        <div className="form-group w-100 position-relative">
                            <label>NAME</label>
                            <Field type="text" name="name"  placeholder="" autoFocus/>
                            <ErrorMessage name="name" component="span" />
                        </div>
                    </div>
                    <div className="form-row  mb-3">
                        <div className="form-group col-md-6 col-12 position-relative">
                            <label>PHONE</label>
                            <Field type="text" name="phone"  placeholder=""/>
                            <ErrorMessage name="phone" component="span" />
                        </div>
                        <div className="form-group col-md-6 col-12 position-relative">
                            <label>EMAIL</label>
                            <Field type="email" name="email" placeholder=""/>
                            <ErrorMessage name="email" component="span" />
                        </div>
                    </div>
                    <div className="form-row mb-3">
                        <div className="form-group w-100 position-relative">
                            <label>WE'RE CURIOS TO FIND OUT MORE ABOUT YOU!</label>
                            <Field name="message" component="textarea" rows="1" placeholder="" />
                            <ErrorMessage name="message" component="span" />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="attach-file position-relative">
                            <input id="file" name="selectedFile" type="file" onChange={(event) =>{this.handleChange(event)}} />
                            <label for="file" className="icon-attach custom-file-label text-uppercase"> attach your CV (doc, docx, pdf, zip, rar)</label>
                            {/* { this.state.selectedFile && <ErrorMessage name="selectedFile" component="span" />} */}
                            { this.state.selectedFile && !this.state.selectedFile.name.split('.')[1].match(allowedTypes) && (<div className="customErrorMsg">Wrong file format.</div>)}
                            { !this.state.selectedFile ? (<div className="customErrorMsg">File attachment is mandatory</div>) : ''}
                            { this.state.outPutText && (<div className="fileNameText">{this.state.outPutText}</div>)}
                        </div>
                    </div>
                    <div className="form-row justify-content-end">
                        <button className="btn" type="submit" disabled={isSubmitting}>APPLY NOW</button>
                    </div>
                </Form>
            )}
        </Formik>
        )
    }
}

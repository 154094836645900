import React from "react"
import List from "./List"
const ReasonToJoinUs = () => {
  return (
    <div className="join-us reasons offset-md-1 col-md-10 col-12" style={{padding:'0'}}>
      <List
        title={"Reasons to join us:"}
        items={[
          "good balance between work and life (eight hour work day, one hour for lunch break, regular holidays, flexible working hours",
          "work on challenging and creative project for clients from Serbia and Europe",
          "possibility of continuous development and stimulating income",
          "cooperation in small and professional team with young people",
          "fair salary depending on your experience, shown initiative, and contribution to the team and projects",
          "reinforce your own skills on dynamic way",
        ]}
      />
      <p className="text-center pointed">We look forward to see you soon!</p>
      
        <h5 className="text-center">Send your CV at <a  href="mailto:info@s7codedesign.com">hr@s7designcreative.com</a></h5>
        
    </div>
  )
}

export default ReasonToJoinUs

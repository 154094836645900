import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../styles/frontend.scss"
import TechHeader from "../../images/header-za-tehnologiju.png";

import 'toasted-notes/src/styles.css';
import CareerForm from '../../components/Career/CareerForm'
import Headline from '../../components/Career/Headline'
import List from '../../components/Career/List'
import ReasonToJoinUs from '../../components/Career/ReasonToJoinUs'
export default class FrontEndDeveloperPage extends React.Component {

    render() {

        return (
            <Layout>
                <SEO title="Laravel/Symphony developer" keywords={[`S7 Design`, `coding`, `develop`,,'PHP', 'Laravel framework', 'Symphony framework', 'web development']} />
                <div className="frontend-main" style={{ backgroundImage: `url(${TechHeader})` }}>
                    <div className="container">
                        <Headline title={'Laravel/Symphony developer'} />
                        <div className="row frontend-main--skills">
                         
                            <div className="col-md-6 col-12">
                            <List 
                               title={"You are excellent candidate if you:"}
                               items={[
                                    'have minimum 1 year of experience working in a professional software development environment',
                                    'have experience with PHP, MySQL , Git',
                                    'have good knowledge of OOP',
                                    'have familiar experience with Linux (Ubuntu)',
                                    'are familiar with Laravel, Symfony',
                                ]}
                            />	
                            <List 
                               title={"Position Type:"}
                               items={[
                                    'full time employment'
                                ]}
                            />	
                            </div>
                            <div className="checked col-md-6 col-12">
                            <List 
                               title={"What we offer:"}
                               items={[
                                    'oppportunity to work in a young, constructive and agile team',
                                    'competitive salary',
                                    'learning and development opportunities',
                                ]}
                            />	
                            </div>
                            <div className="w-100 height"></div>
                            <ReasonToJoinUs  />
                            <div className=" offset-md-1 col-md-10 col-12">
                                <CareerForm contactTitle="Laravel/Symphony developer"/>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )

    }
}

import React from "react"
import "../../styles/frontend.scss"
const List = ({ items, title }) => {
  return (
    <div className="checked">
     { title&&  <h3>{title}</h3>}
     {
         items && items.length > 0 &&
         <ul>
             { items.map((item, index) => ( <li key={index}><span></span>{item}</li>))}
         </ul>

     }
      
    </div>
  )
}

export default List;

import React from 'react'

const Headline = ({title}) => {
    return (
        <>
        <div className="row justify-content-center we-are-hiring">
                <div className="col-md-10 col-12">
                    <h1 className="text-center">We are hiring!</h1>
                    <p className="text-center">We are looking for employees who is professional,
                        dedicated, cooperative and ready for teamwork.
                    </p>
                </div>
            </div>
         { 
         title && 
             <div className="row justify-content-center career-title__wrapp">
                <h2 className="text-center career-title">{title}</h2>
             </div>}
        </>
    )
}

export default Headline
